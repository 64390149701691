import { History, InsertChart, Timer } from "@mui/icons-material";
import { GridLocaleText } from "@mui/x-data-grid";
import { ListElement } from "./types";
import { roles } from "../pages/costants";

export const lightColor: string = "#E6E6E6"; //"#4FFFA2";
export const darkColor: string = "#3C3C3B"; //"#000000";
export const chartColor = {
  error: "#F14949",
  warning: "#FFC736",
  success: "#61D473",
  purple: "#B400FF",
  green: "#4FFFA2",
};

//TABLE TEXT
export const customLocaleText: (t: any) => Partial<GridLocaleText> = (
  t: any
) => ({
  columnMenuSortAsc: t("sortAsc"),
  columnMenuSortDesc: t("sortDesc"),
  columnMenuUnsort: t("unsort"),
  columnMenuFilter: t("filter"),
  columnMenuHideColumn: t("hideColumn"),
  columnMenuShowColumns: t("showColumns"),
  columnMenuManageColumns: t("manageColumns"),
  columnsPanelHideAllButton: t("hideAll"),
  columnsPanelShowAllButton: t("showAll"),
  columnsPanelTextFieldLabel: `${t("findColumn")}`,
  columnsPanelTextFieldPlaceholder: `${t("search")}`,
  filterPanelAddFilter: t("addFilter"),
  filterPanelInputLabel: `${t("search")}`,
  filterPanelInputPlaceholder: `${t("search")}`,
  filterPanelRemoveAll: t("removeAll"),
  filterPanelColumns: t("columns"),
  filterPanelOperator: t("operators"),
  filterOperatorIsEmpty: `${t("isEmpty")}`,
  filterOperatorContains: `${t("contains")}`,
  filterOperatorStartsWith: `${t("startsWith")}`,
  filterOperatorEndsWith: `${t("endsWith")}`,
  filterOperatorIsNotEmpty: `${t("isNotEmpty")}`,
  filterOperatorEquals: `${t("equals")}`,
  filterOperatorIsAnyOf: `${t("isAnyOf")}`,
});

//NAVBAR OPTIONS
export const homeOptions: (t: any) => ListElement[] = (t: any) => [
  {
    primaryText: t("dashboard"),
    onClick: () => (window.location.pathname = "/dashboard"),
    startIcon: <InsertChart sx={{ color: lightColor, fontSize: "40px" }} />,
  },
  {
    primaryText: t("dataHistory"),
    onClick: () => (window.location.pathname = "/dataHistory"),
    startIcon: <History sx={{ color: lightColor, fontSize: "40px" }} />,
  },
  {
    primaryText: t("alarmsHistory"),
    onClick: () => (window.location.pathname = "/alarms"),
    startIcon: <Timer sx={{ color: lightColor, fontSize: "40px" }} />,
  },
];

export const capitalizedString = (inputString: string) =>
  inputString
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

//VALIDATION
export function isValidIPv4(str: string) {
  return /^(?:(?:25[0-5]|2[0-4]\d|[01]?\d\d?)\.){3}(?:25[0-5]|2[0-4]\d|[01]?\d\d?)$/g.test(
    str
  );
}

export function isValidSSID(str: string) {
  return /^[^!#;+\]/"\t][^+\]/"\t]{0,30}[^ +\]/"\t]$|^[^ !#;+\]/"\t]$[ \t]+$/g.test(
    str
  );
}

export function isValidWPA2(str: string) {
  return /^[\u0020-\u007e]{8,63}$/g.test(str);
}

// JSON
export const prepareNetJson = (customWifi: { IFCS: any[] }, apn: string) => {
  return {
    IFCS: [
      { ...customWifi.IFCS[0] },
      {
        ifc_name: "cellular",
        ifc_params: { apn: apn },
      },
    ],
  };
};

export const prepareParamsJson = (
  price1: number,
  price2: number,
  amount1: number,
  amount2: number,
  machineThresholds: number[],
  grinder1Thresholds: number[],
  grinder2Thresholds: number[],
  attraversamento: number,
  macchinaWThreshold: number,
  macina1WThreshold: number,
  macina2WThreshold: number,
  macchinaTriphase: boolean,
  macina1Triphase: boolean,
  macina2Triphase: boolean
) => {
  return {
    macchina: {
      sensor: "CH1",
      clamp_type: "05V",
      amperage: 150,
      w_thresh: macchinaWThreshold,
      v_eff: 230,
      offset: -3.5,
      three_phase: macchinaTriphase,
      rolling_mode: "avg",
      buff_len: 1,
      th: machineThresholds,
    },
    macina_1: {
      sensor: "CH2",
      clamp_type: "05V",
      amperage: 150,
      w_thresh: macina1WThreshold,
      v_eff: 230,
      offset: -3.5,
      three_phase: macina1Triphase,
      rolling_mode: "avg",
      buff_len: 1,
      th: grinder1Thresholds,
      costo: price1,
      "g/dose": amount1,
    },
    macina_2: {
      sensor: "CH3",
      clamp_type: "05V",
      amperage: 150,
      w_thresh: macina2WThreshold,
      v_eff: 230,
      offset: -3.5,
      three_phase: macina2Triphase,
      rolling_mode: "avg",
      buff_len: 1,
      th: grinder2Thresholds,
      costo: price2,
      "g/dose": amount2,
    },
    EV1: {
      sensor: "EV1",
      digital_type: "res",
      init_value: 0,
      rolling_mode: "max",
      buff_len: 1,
      type: "NO",
    },
    EV2: {
      sensor: "EV2",
      digital_type: "res",
      init_value: 0,
      rolling_mode: "max",
      buff_len: 1,
      type: "NO",
    },
    EV3: {
      sensor: "EV3",
      digital_type: "res",
      init_value: 0,
      rolling_mode: "max",
      buff_len: 1,
      type: "NO",
    },
    t_attraversamento: attraversamento,
    th_allarme: 5,
  };
};

export const prepareSensorsJson = {
  CH1: {
    set: {
      type: "voltage",
      exp: null,
      input_number: 4,
      PGA: 2,
      SPS: 3300,
    },
    conversion: {
      type: "linear",
      args: {
        x_min: 0,
        x_max: 10,
        y_min: 0,
        y_max: 10,
        offset: 0,
        under_x: 0.0,
        over_x: 10.0,
      },
    },
  },
  CH2: {
    set: {
      type: "voltage",
      exp: null,
      input_number: 5,
      PGA: 2,
      SPS: 3300,
    },
    conversion: {
      type: "linear",
      args: {
        x_min: 0,
        x_max: 10,
        y_min: 0,
        y_max: 10,
        offset: 0,
        under_x: 0.0,
        over_x: 10.0,
      },
    },
  },
  CH3: {
    set: {
      type: "voltage",
      exp: null,
      input_number: 6,
      PGA: 2,
      SPS: 3300,
    },
    conversion: {
      type: "linear",
      args: {
        x_min: 0,
        x_max: 10,
        y_min: 0,
        y_max: 10,
        offset: 0,
        under_x: 0.0,
        over_x: 10.0,
      },
    },
  },
  EV1: {
    set: {
      type: "resistive",
      exp: null,
      input_number: 1,
      PGA: 2,
      SPS: 3000,
    },
    conversion: {
      type: "lookup_table",
      args: {
        v_min: -50,
        ref_table: [0, 1],
        delta: 5,
        offset: 0,
        out_of_range: 100.0,
      },
    },
  },
  EV2: {
    set: {
      type: "resistive",
      exp: null,
      input_number: 2,
      PGA: 2,
      SPS: 3000,
    },
    conversion: {
      type: "lookup_table",
      args: {
        v_min: -50,
        ref_table: [0, 1],
        delta: 5,
        offset: 0,
        out_of_range: 100.0,
      },
    },
  },
  EV3: {
    set: {
      type: "resistive",
      exp: null,
      input_number: 3,
      PGA: 2,
      SPS: 3000,
    },
    conversion: {
      type: "lookup_table",
      args: {
        v_min: -50,
        ref_table: [0, 1],
        delta: 5,
        offset: 0,
        out_of_range: 100.0,
      },
    },
  },
};

export const prepareConfigJson = {
  params: {
    wtd: 60000,
    acq_freq: 500,
    fast_freq: 0,
    pub_freq: 1000,
    agent_timeout: 1500,
    store_period: 60,
    info_period: 300,
    exc_period: 300,
    dn_chunk_size: 32768,
    dn_wtd: 120000,
    offline_thr: 20,
    resolve_thr: 5,
    tlog_rsize: 1600,
    tlog_bsize: 512,
    tlog_thr: 2,
  },
};

export const prepareNetTestJson = {
  IFCS: [
    {
      ifc_name: "wifi",
      ifc_params: {
        ssid: "Zerynth",
        pwd: "TOIZerynth2021",
      },
    },
  ],
};

//DASHBOARD
export const dashboardUrl = (
  role: string,
  id: string,
  language: "it" | "en",
  macinatori?: string
) => {
  if (role === roles.installer) {
    if (macinatori && macinatori === "1") {
      if (language === "it") {
        return `https://grafana.app.idealcup.zerynth.com/d/installer_single_grinder/installatore_1macinatore?orgId=1&refresh=2s&kiosk=tv&var-device_id=${id}`;
      }
      if (language === "en") {
        return `https://grafana.app.idealcup.zerynth.com/d/installer__single_grinder/installer_single_grinder?orgId=1&refresh=2s&kiosk=tv&var-device_id=${id}`;
      }
    }
    if (macinatori && macinatori === "2") {
      if (language === "it") {
        return `https://grafana.app.idealcup.zerynth.com/d/installer/installatore?orgId=1&refresh=2s&kiosk=tv&var-device_id=${id}`;
      }
      if (language === "en") {
        return `https://grafana.app.idealcup.zerynth.com/d/installer_double_grinder/installer_double_grinder?orgId=1&refresh=2s&kiosk=tv&var-device_id=${id}`;
      }
    }
  }
  if (role === roles.roasting) {
    if (macinatori && macinatori === "1") {
      if (language === "it") {
        return `https://grafana.app.idealcup.zerynth.com/d/torrefazione_macinatore1/torrefazione_1macinatore?orgId=1&refresh=2s&kiosk=tv&var-device_id=${id}`;
      }
      if (language === "en") {
        return `https://grafana.app.idealcup.zerynth.com/d/roasting_single_grinder/roasting_single_grinder?orgId=1&refresh=2s&kiosk=tv&var-device_id=${id}`;
      }
    }
    if (macinatori && macinatori === "2") {
      if (language === "it") {
        return `https://grafana.app.idealcup.zerynth.com/d/torrefazione_2macinatori/torrefazione_2macinatori?orgId=1&refresh=2s&kiosk=tv&var-device_id=${id}`;
      }
      if (language === "en") {
        return `https://grafana.app.idealcup.zerynth.com/d/roasting_double_grinder/roasting_double_grinder?orgId=1&refresh=2s&kiosk=tv&var-device_id=${id}`;
      }
    }
  }
  if (role === roles.customer) {
    if (macinatori && macinatori === "1") {
      if (language === "it") {
        return `https://grafana.app.idealcup.zerynth.com/d/cliente_macinatore1/cliente_1macinatore?orgId=1&refresh=2s&kiosk=tv&var-device_id=${id}`;
      }
      if (language === "en") {
        return `https://grafana.app.idealcup.zerynth.com/d/final_user_single_grinder/final_user_singlegrinder?orgId=1&refresh=2s&kiosk=tv&var-device_id=${id}`;
      }
    }
    if (macinatori && macinatori === "2") {
      if (language === "it") {
        return `https://grafana.app.idealcup.zerynth.com/d/cliente_2macinatori/cliente_2macinatori?orgId=1&refresh=2s&kiosk=tv&var-device_id=${id}`;
      }
      if (language === "en") {
        return `https://grafana.app.idealcup.zerynth.com/d/final_user_double_grinder/final_user_doublegrinder?orgId=1&refresh=2s&kiosk=tv&var-device_id=${id}`;
      }
    }
  }
};

//FORMAT DATE
export function formatDate(date: any) {
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");
  return `${year}-${month}-${day}`; // Funzione per formattare la data nel formato YYYY-MM-DD
}
export function formattedMonth(date: any) {
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  return `${month}/${year}`; // Funzione per formattare la data nel formato YYYY-MM-DD
}

//FORMAT MONTH
export function formatMonth(t: any, month: number) {
  if (month === 1) return t("january");
  if (month === 2) return t("february");
  if (month === 3) return t("march");
  if (month === 4) return t("april");
  if (month === 5) return t("may");
  if (month === 6) return t("june");
  if (month === 7) return t("july");
  if (month === 8) return t("august");
  if (month === 9) return t("september");
  if (month === 10) return t("october");
  if (month === 11) return t("november");
  if (month === 12) return t("december");
  return String(month);
}
